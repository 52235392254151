import ListSubheader from "@mui/joy/ListSubheader";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import {Modal, ModalDialog} from "@mui/joy";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import HouseCard from "./HouseCard";
import List from "@mui/joy/List";
import * as React from "react";
import {useState} from "react";
import CreateHouse from "../CreateHouse";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import SortableTaskItem from "./SortableTaskItem.tsx";
import {useDroppable} from "@dnd-kit/core";

export default function BoardList({id, list, listId, handleSnackbarOpen, boardId}) {
    const {setNodeRef} = useDroppable({
        id,
    });

    const [data, setData] = useState(null);
    const createHouse = CreateHouse();
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(null);

    const getBody = (data) => {
        const link = data.url.replace(/(\d+)[^d]*$/, '$1');
        return {link: link, status: data.status.toLowerCase(), boardId: data.boardId};
    };

    const handleSubmit = () => {
        const body = getBody(data);
        if (body.link.includes('https://www.rightmove.co.uk')) {
            createHouse.mutate(body);
            setOpenModal(false);
        } else {
            setError("We only support Rightmove links")
        }
    }

    return (
        <List
            variant="plain"
            color="neutral"
            sx={{
                display: 'flex',
                flexDirection: 'list',
                alignItems: 'center',
                gridRow: '1',
                gridColumn: 'span 2',
                height: '100%',
                minWidth: '360px',
                overflow: 'auto',
                gap: 2
            }}
            key={listId}
        >
            <ListSubheader
                sx={{letterSpacing: '2px', fontWeight: '800', alignSelf: 'center'}}>
                {list.status}
            </ListSubheader>
            <Button
                variant="soft"
                color="neutral"
                sx={{width: '60%', alignSelf: 'center'}}
                onClick={() => setOpenModal(true)}
            >
                <Typography level="body-sm">
                    +
                </Typography>
            </Button>
            <Modal
                aria-labelledby="modal-status"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <ModalDialog>
                    <ModalClose onClick={() => setError(null)}/>
                    <Typography>Add new house</Typography>
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit()
                    }}>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Link</FormLabel>
                                <Input autoFocus required
                                       onChange={(event) => setData({
                                           url: event.target.value,
                                           status: list.status,
                                           boardId: boardId
                                       })}/>
                            </FormControl>
                            <Button type="submit" disabled={error}>Submit</Button>
                        </Stack>
                    </form>
                    {error && <Typography level="body-sm" color="danger">{error}</Typography>}
                </ModalDialog>
            </Modal>
            <SortableContext
                id={listId}
                items={list.items}
                strategy={verticalListSortingStrategy}
            >
                <div ref={setNodeRef}>
                    {list.items.map((house, index) => (
                        <SortableTaskItem id={house.id} key={house.id} house={house}>
                            <HouseCard
                                house={house}
                                index={index}
                                handleSnackbarOpen={handleSnackbarOpen}
                            />
                        </SortableTaskItem>
                    ))}
                </div>
            </SortableContext>
        </List>
    );
}
