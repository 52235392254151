import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {APP_API} from "./App";

function CreateBoard() {
    const {getIdToken} = useKindeAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data) => {
            const authToken = await getIdToken();
            if (!authToken) {
                console.log("No token found");
                return {data: []};
            }
            const fetchData = await fetch(`${APP_API}/api/board`, {
                method: 'POST',
                headers: {
                    'Authorization': authToken
                },
                body: JSON.stringify(data)
            });
            if (!fetchData.ok) {
                return {data: []};
            }
            return fetchData.json
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['house']})
            queryClient.invalidateQueries({queryKey: ['board']})
            Window.location.reload()
        }
    })
}

export default CreateBoard;